import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  registerForm: FormGroup;
  year: any;
  submitted: boolean = false;
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    const currentDate = new Date();
    let twoMonthslater = new Date(currentDate);
    this.year = twoMonthslater.getFullYear();
    this.registerForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
      },
      {}
    );
  }
  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
  }
}
