import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { InscriptionMedecinComponent } from './components/inscription-medecin/inscription-medecin.component';
import { InscriptionPatientComponent } from './components/inscription-patient/inscription-patient.component';
import { ConnexionComponent } from './components/connexion/connexion.component';
import { MotDePasseOublieMedecinComponent } from './components/mot-de-passe-oublie-medecin/mot-de-passe-oublie-medecin.component';
import { MedecinComponent } from './components/medecin/medecin.component';
import { PharmacieComponent } from './components/pharmacie/pharmacie.component';
import { MotDePasseInitialiserMedecinComponent } from './components/mot-de-passe-initialiser-medecin/mot-de-passe-initialiser-medecin.component';
import { EventDeatilsComponent } from './components/event-deatils/event-deatils.component';

const routes: Routes = [
  
  { path: '', component: HomeComponent },
  { path: 'inscription/medecin', component: InscriptionMedecinComponent },
  { path: 'inscription/patient', component: InscriptionPatientComponent },
  { path: 'mot-de-passe-oublie/medecin', component: MotDePasseOublieMedecinComponent },
  { path: 'mot-de-passe-initialiser/medecin/:restoken', component: MotDePasseInitialiserMedecinComponent },
  { path: 'connexion', component: ConnexionComponent },
  { path: 'medecin', component:MedecinComponent},
  { path: 'pharmacie', component:PharmacieComponent},
  { path: 'event/:title', component: EventDeatilsComponent },
  { path: '**', redirectTo: '/' } // Wildcard route for unknown paths

  
 
  



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
