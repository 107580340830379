<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="card col-lg-6 col-md-8 col-sm-10 p-5">
            <h2 class="text-center">Mot de passe oublié</h2>
            <br>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label for="email">Numéro d'inscription <b style="color: red">*</b></label>
                    <input type="text" class="form-control" placeholder="Numéro d'inscription"
                        formControlName="numInscription"
                        [ngClass]="{ 'is-invalid': submitted && f.numInscription.errors }"  onkeypress="return /[0-9]/.test(event.key)" />
                    <div *ngIf="submitted  && f.numInscription.errors" class="invalid-feedback">
                        <div *ngIf="f.numInscription.errors.required">
                            Ce champ est obligatoire.
                        </div>
                        <div *ngIf="f.numInscription.errors.maxLength">
                            Le numéro doit contenir maximum 11 chiffres.
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="email">Email <b style="color: red">*</b></label>
                    <input type="email" class="form-control" id="email" placeholder="E-mail" formControlName="email"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">
                            Veuillez renseigner ce champ.
                        </div>
                        <div *ngIf="f.email.errors.email">
                            Merci de saisir une adresse email valide.
                        </div>
                    </div>
                </div>
                <br>
                <div class="text-center">
                    <button type="submit" class="btn btn-primary"
                        [attr.disabled]="!isValid ? true : null">Envoyer</button>
                </div>
            </form>
        </div>
    </div>
</header>
<app-footer></app-footer>
