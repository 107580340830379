import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONFIG, _getURL } from 'config/API_CONFIG';
import { Observable } from 'rxjs';

@Injectable()
export class EvenementV2Service{
  
  private _eventURL = _getURL(API_CONFIG.evenement)
  
  constructor(private http: HttpClient) { }
  getAllEvents(): Observable<any> {
    return this.http.get(`${this._eventURL}/allEvent`);
  }
  getAllEventByUrl(url): Observable<any> {
    return this.http.get(`${this._eventURL}/event/unique/url/`+url);
  }
}
