<app-navbar></app-navbar>
<header class="masthead" id="accueil">
  <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
    <div class="card col-lg-6 col-md-8 col-sm-10 p-5">
      <h2 class="text-center">Mot de passe oublié</h2>
      <br>
      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="email">Mot de passe <b style="color: red">*</b></label>
          <input type="password" id="password" name="password" class="form-control" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">
              Ce champ est obligatoire.
            </div>
            <div *ngIf="f.password.errors.minlength">
              Le mot de passe doit contenir au moins 6 caractères
            </div>
            <div *ngIf="f.password.errors.maxlength">
              Le mot de passe doit contenir au maximum 50 caractères.
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Confirmation du mot de passe <b style="color: red">*</b></label>
           <input type="password" id="password" name="password" class="form-control" formControlName="confirmationPassword"
                [ngClass]="{ 'is-invalid': submitted && f.confirmationPassword.errors }" />
              <div *ngIf="submitted && f.confirmationPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmationPassword.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f.confirmationPassword.errors.minlength">
                  Le mot de passe doit contenir au moins 6 caractères
                </div>
                <div *ngIf="f.confirmationPassword.errors.maxlength">
                  Le mot de passe doit contenir au maximum 50 caractères.
                  </div>
                <div *ngIf="f.confirmationPassword.errors.mustMatch">Les mots de passe ne correspondant pas. </div>
              </div>
        </div>
        <br>
        <div class="text-center">
          <button type="submit" class="btn btn-primary" [attr.disabled]="!isValid ? true : null">Envoyer</button>
        </div>
      </form>
    </div>
  </div>
</header>
<app-footer></app-footer>
