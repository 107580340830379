import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InscriptionMedecinComponent } from './components/inscription-medecin/inscription-medecin.component';
import { InscriptionPatientComponent } from './components/inscription-patient/inscription-patient.component';
import { ConnexionComponent } from './components/connexion/connexion.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CookiesComponent } from './shared/cookies/cookies.component';
import { MotDePasseOublieMedecinComponent } from './components/mot-de-passe-oublie-medecin/mot-de-passe-oublie-medecin.component';
import { MotDePasseOubliePatientComponent } from './components/mot-de-passe-oublie-patient/mot-de-passe-oublie-patient.component';
import { CookieService } from 'ngx-cookie-service';
import { Ng2IziToastModule } from "ng2-izitoast";
import { MedecinComponent } from './components/medecin/medecin.component';
import { PharmacieComponent } from './components/pharmacie/pharmacie.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ArticleService } from 'src/app/services/article.service';
import { MotDePasseInitialiserMedecinComponent } from './components/mot-de-passe-initialiser-medecin/mot-de-passe-initialiser-medecin.component';
import { EventDeatilsComponent } from './components/event-deatils/event-deatils.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    InscriptionMedecinComponent,
    InscriptionPatientComponent,
    ConnexionComponent,
    CookiesComponent,
    MotDePasseOublieMedecinComponent,
    MotDePasseOubliePatientComponent,
    MedecinComponent,
    PharmacieComponent,
    MotDePasseInitialiserMedecinComponent,
    EventDeatilsComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    Ng2IziToastModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    HttpClientModule,
    NgxIntlTelInputModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    
    
    
  ],
  providers: [CookieService,ArticleService,{
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptcha.siteKey,
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
