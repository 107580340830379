import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_CONFIG,_getURL } from 'config/API_CONFIG';

@Injectable({
  providedIn: 'root'
})
export class MedecinService {

  private apiUrl = _getURL(API_CONFIG.medecin)+`/search/medecin`; // Mettez l'URL de votre API ici
  private apiUrl2 = _getURL(API_CONFIG.pharmacie)+`/searchPharmacie`; // Mettez l'URL de votre API ici
  private apisearchbyName = _getURL(API_CONFIG.medecin)+`/search/medecin/by/fisrtName/or/lastName`; // Mettez l'URL de votre API ici


  constructor(private http: HttpClient) {}

  searchMedecin(prenom_nom?: string, specialite?: string, gouvernorat?: string, page: number = 1, limit: number = 10): Observable<any> {
    // Construire l'URL en fonction des paramètres fournis, y compris la pagination
    let url = `${this.apiUrl}?page=${page}&limit=${limit}`;
    if (prenom_nom) {
      url += '&prenom_nom=' + prenom_nom;
    }
    if (specialite) {
      url += '&specialite=' + specialite;
    }
    if (gouvernorat) {
      url += '&gouvernorat=' + gouvernorat;
    }

    // Effectuer la requête GET
    return this.http.get(url);
  }
  searchPharmacie(nom?: string, specialite?: string, gouvernorat?: string, page: number = 1, limit: number = 10): Observable<any> {
    // Construire l'URL en fonction des paramètres fournis, y compris la pagination
    let url = `${this.apiUrl2}?page=${page}&limit=${limit}`;
    if (nom) {
      url += '&nom=' + nom;
    }
    if (specialite) {
      url += '&specialite=' + specialite;
    }
    if (gouvernorat) {
      url += '&gouvernorat=' + gouvernorat;
    }

    // Effectuer la requête GET
    return this.http.get(url);
  }
  verifyNumInscription(admin) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin) + '/verifyNumInscription', (admin), { headers: headers })
  }
  registerMedecin(newMedecin) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin), (newMedecin), { headers: headers })
  }
  authenticateMedecinNewVersion(medecin){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.authenticateMedecin), medecin, { headers: headers })
  }
  authenticateMedecinNewVersionCode(medecin){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.authenticateMedecinCode), medecin, { headers: headers })
  }
  recoverPassword(medecin) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.medecin) + '/forgot', (medecin), { headers: headers })
      
  }
  resetPassword(medecin, token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.medecin) + '/reset/' + token, (medecin), { headers: headers })
      
  }
  searchMedecinByFirstNameOrLastName(nom: string, page: number, limit: number): Observable<any> {
    return this.http.get<any>(`${this.apisearchbyName}`, {
      params: {
        nom: nom,
        page: page.toString(),
        limit: limit.toString()
      }
    });
  }

}
