<app-navbar></app-navbar>
<header class="masthead" id="accueil">
  <div
    class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center"
  >
    <div class="d-flex justify-content-center">
      <div class="text-center">
        <h1 class="mx-auto my-0">Votre santé, notre priorité</h1>
        <h2 class="text-white mx-auto mt-2 mb-5 text-shadow">
          Améliorer la gestion des dossiers médicaux en simplifiant le partage
          d'informations entre le patient et ses médecins
        </h2>
        <a
          class="btn btn-connexion"
          data-toggle="modal"
          data-target="#exampleModalArchiver"
          style="color: #fff"
          >Demander une démo</a
        >
      </div>
    </div>
  </div>
</header>

<section class="projects-section bg-light" id="localisation-medicale">
  <div style="margin-bottom: 3rem">
    <h1
      class="text-center"
      style="
        font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
          Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
          Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 3rem;
        line-height: 2.5rem;
        letter-spacing: 0.5rem;
        color: #23b1a5;
      "
    >
      Localisation médicale
    </h1>
    <hr
      class="text-primary custom-line"
      style="width: 10%; margin: auto; margin-top: 1.2rem"
    />
  </div>
  <div class="container">
    <ul class="nav nav-pills justify-content-md-start">
      <li class="nav-item" style="list-style: none !important">
        <a
          class="nav-link"
          [class.selected]="selectedOption === 'medecin'"
          (click)="selectedOption = 'medecin'"
        >
          <i class="fas fa-user-md mr-1"></i> Médecin
        </a>
      </li>
      <li class="nav-item" style="list-style: none !important">
        <a
          class="nav-link"
          [class.selected]="selectedOption === 'pharmacie'"
          (click)="selectedOption = 'pharmacie'"
        >
          <i class="fas fa-pills mr-1"></i> Pharmacie
        </a>
      </li>
    </ul>

    <div class="row mt-2" *ngIf="selectedOption === 'medecin'">
      <div class="col-md-4 mr-3">
        <div class="input-group my-3">
          <input
            type="text"
            class="form-control"
            placeholder="Nom du médecin"
            style="height: 50px"
            [(ngModel)]="nomMedecin"
            onkeypress="return /[a-zA-Zéè]/.test(event.key)"
          />
          <div class="input-group-append">
            <span class="input-group-text"><i class="fas fa-user-md"></i></span>
          </div>
        </div>
      </div>

      <div class="col-md-3 mr-3">
        <div class="input-group my-3">
          <ng-select
            style="height: 50px"
            [items]="specialiites"
            class="form-control input-custom"
            placeholder="Spécialité"
            [closeOnSelect]="true"
            [(ngModel)]="specialiteMedecin"
          >
          </ng-select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><i class="fas fa-stethoscope"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="col-md-3 mr-3">
        <div class="input-group my-3">
          <ng-select
            style="height: 50px"
            [items]="villesTunisiennes"
            class="form-control input-custom"
            placeholder="Localisation"
            [closeOnSelect]="true"
            [(ngModel)]="villeMedecin"
          >
          </ng-select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><i class="fas fa-map-marker-alt"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="col-md-1 mt-3">
        <button
          class="btn btn-connexion"
          (click)="rechercherMedecins()"
          style="height: 50px"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>

    <div class="row mt-2" *ngIf="selectedOption === 'pharmacie'">
      <div class="col-md-4 mr-3">
        <div class="input-group my-3">
          <input
            type="text"
            class="form-control"
            placeholder="Nom de la pharmacie"
            style="height: 50px"
            [(ngModel)]="nomPharmacie"
            onkeypress="return /[a-zA-Zéè]/.test(event.key)"
          />
          <div class="input-group-append">
            <span class="input-group-text"
              ><i class="fas fa-hospital"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="col-md-3 mr-3">
        <div class="input-group my-3">
          <ng-select
            style="height: 50px"
            [items]="typesPharmacies"
            class="form-control input-custom"
            placeholder="Type de pharmacie"
            [closeOnSelect]="true"
            [(ngModel)]="typePharmacie"
          >
          </ng-select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><i class="fas fa-prescription-bottle-alt"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="col-md-3 mr-3">
        <div class="input-group my-3">
          <ng-select
            style="height: 50px"
            [items]="villesTunisiennes"
            class="form-control input-custom"
            placeholder="Localisation"
            [closeOnSelect]="true"
            [(ngModel)]="villePharmacie"
          >
          </ng-select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><i class="fas fa-map-marker-alt"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="col-md-1 mt-3">
        <button
          class="btn btn-connexion"
          (click)="rechercherPharmacies()"
          style="height: 50px"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
  </div>
</section>

<section class="projects-section bg-light" id="portes-vers-la-sante">
  <div style="margin-bottom: 3rem">
    <h1
      class="text-center"
      style="
        font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
          Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
          Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 3rem;
        line-height: 2.5rem;
        letter-spacing: 0.5rem;
        color: #23b1a5;
      "
    >
      Portes vers la santé
    </h1>
    <hr
      class="text-primary custom-line"
      style="width: 10%; margin: auto; margin-top: 1.2rem"
    />
  </div>
  <div>
    <div class="row no-gutters">
      <div class="col-md-6 d-flex">
        <div class="bloc bg-primary text-white p-5 d-flex flex-column" style="background-color: #A1C9C7 !important;">
          <h2 class="text-center">VOUS ÊTES MÉDECIN ?</h2>
          <br />
          <p class="flex-grow-1">
            <li>Améliorez les processus et centralisez les informations.</li>
            <li>
              Simplifiez le stockage et la récupération des dossiers de vos
              patients.
            </li>
            <li>Consultez les informations médicales à distance.</li>
            <li>
              Assurez la confidentialité des informations médicales de vos
              patients en respectant les normes de sécurité.
            </li>
            <li>
              Organisez efficacement vos rendez-vous avec la possibilité de
              recevoir des rappels automatiques.
            </li>
          </p>
          <br />
          <div class="mt-auto text-center">
            <button
              class="btn btn-green"
              (click)="redirectToInscriptionMedecin()"
            >
              Inscrivez-vous
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex">
        <div class="bloc bg-secondary text-white p-5 d-flex flex-column" style="background-color: #23b1a5 !important">
          <h2 class="text-center">VOUS ÊTES UN PATIENT ?</h2>
          <br />
          <p class="flex-grow-1">
            <li>Consultez vos informations médicales en ligne.</li>
            <li>
              Facilitez le partage de votre dossier médical avec vos médecins.
            </li>
            <li>
              Accédez aux détails de vos traitements, prescriptions et documents
              en toute simplicité.
            </li>
            <li>
              Planifiez vos rendez-vous et recevez des rappels automatiques.
            </li>
            <li>
              Vos informations médicales sont sécurisées en conformité avec les
              règlements en vigueur.
            </li>
          </p>
          <br />
          <div class="mt-auto text-center">
            <button
              class="btn btn-light"
              (click)="redirectToInscriptionPatient()"
            >
              Inscrivez-vous
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="projects-section bg-light" id="pourquoi-utiliser-medivisto">
  <div style="margin-bottom: 3rem">
    <h1
      class="text-center"
      style="
        font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
          Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
          Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 3rem;
        line-height: 2.5rem;
        letter-spacing: 0.5rem;
        color: #23b1a5;
      "
    >
      Pourquoi utiliser Medivisto ?
    </h1>
    <hr
      class="text-primary custom-line"
      style="width: 10%; margin: auto; margin-top: 1.2rem"
    />
  </div>
  <div class="container">
    <div class="row justify-content-around">
      <!-- Première carte -->
      <div class="col-sm-2">
        <div
          class="card h-100 d-flex flex-column justify-content-center align-items-center"
          (mouseenter)="showDescription(1)"
          (mouseleave)="hideDescription(1)"
        >
          <div class="card-body text-center" *ngIf="!showingDescription[1]">
            <img
              src="../../../assets/img/24sur7.webp"
              alt="Image 1"
              style="max-width: 100%; height: auto"
            />
            <h6 class="card-title">Prise de rendez-vous</h6>
          </div>
          <p class="text-center" *ngIf="showingDescription[1]">
            Prenez un rendez-vous 24/7 et organisez votre agenda facilement.
          </p>
        </div>
      </div>
      <!-- Deuxième carte -->
      <div class="col-sm-2">
        <div
          class="card h-100 d-flex flex-column justify-content-center align-items-center"
          (mouseenter)="showDescription(2)"
          (mouseleave)="hideDescription(2)"
        >
          <div class="card-body text-center" *ngIf="!showingDescription[2]">
            <img
              src="../../../assets/img/dossier-medical.webp"
              alt="Image 2"
              style="max-width: 100%; height: auto"
            />
            <h6 class="card-title">Base de données en ligne</h6>
          </div>
          <p class="card-text text-center p-2" *ngIf="showingDescription[2]">
            Profitez d'une base de données complète et facilement accessible
            regroupant les informations de vos patients et leurs dossiers
            médicaux.
          </p>
        </div>
      </div>
      <!-- Troisième carte -->
      <div class="col-sm-2">
        <div
          class="card h-100 d-flex flex-column justify-content-center align-items-center"
          (mouseenter)="showDescription(3)"
          (mouseleave)="hideDescription(3)"
        >
          <div class="card-body text-center" *ngIf="!showingDescription[3]">
            <img
              src="../../../assets/img/gain-temps.webp"
              alt="Image 3"
              style="max-width: 100%; height: auto"
            />
            <h6 class="card-title">Gain du temps</h6>
          </div>
          <p class="text-center" *ngIf="showingDescription[3]">
            Optimisez le temps pendant vos consultations pour une gestion plus
            efficace.
          </p>
        </div>
      </div>
      <!-- Quatrième carte -->
      <div class="col-sm-2">
        <div
          class="card h-100 d-flex flex-column justify-content-center align-items-center"
          (mouseenter)="showDescription(4)"
          (mouseleave)="hideDescription(4)"
        >
          <div class="card-body text-center" *ngIf="!showingDescription[4]">
            <img
              src="../../../assets/img/historique.webp"
              alt="Image 4"
              style="max-width: 100%; height: auto"
            />
            <h6 class="card-title">Historique complet</h6>
          </div>
          <p class="card-text text-center p-2" *ngIf="showingDescription[4]">
            Bénéficiez d'un accès rapide à un historique exhaustif de vos
            consultations, examens et interventions en seulement quelques clics.
          </p>
        </div>
      </div>
      <!-- Cinquième carte -->
      <div class="col-sm-2">
        <div
          class="card h-100 d-flex flex-column justify-content-center align-items-center"
          (mouseenter)="showDescription(5)"
          (mouseleave)="hideDescription(5)"
        >
          <div class="card-body text-center" *ngIf="!showingDescription[5]">
            <img
              src="../../../assets/img/securite.webp"
              alt="Image 5"
              style="max-width: 100%; height: auto"
            />
            <h6 class="card-title">Sécurité</h6>
          </div>
          <p class="text-center" *ngIf="showingDescription[5]">
            Autorisation n° 20/02-5040 pour le traitement des données
            personnelles de santé autorisé par l 'INPDP.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="projects-section bg-light" id="evenement">
  <div style="margin-bottom: 3rem">
    <h1
    class="text-center"
    style="
      font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
        Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
        Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      font-size: 3rem;
      line-height: 2.5rem;
      letter-spacing: 0.5rem;
      color: #23b1a5;
    "
  >
   Actualité santé
  </h1>
    <hr class="text-primary custom-line" style="width: 10%; margin: auto; margin-top: 1.2rem" />
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <!-- Card 1 -->
      <div *ngFor="let event of events" class="col-md-4 mb-4" itemscope itemtype="https://schema.org/MedicalEvent"> <!-- Use MedicalEvent type -->
        <div class="card h-100 mx-md-2 event shadow-lg" (click)="onCardClick(event.url)">
          <div class="ratio ratio-4x3">
            <img [src]="baseImageUrl + event.key + '/'+ event.image" class="card-img-top" alt="Lancement du site" style="object-fit: cover; filter: brightness(1.2);"> <!-- Augmenter la luminosité -->
          </div>
          <div class="card-body text-center">
            <h6 class="card-title event-title" itemprop="name" style="color: #23b1a5 !important;">{{event.title}}</h6> <!-- Changer la couleur à #000 pour un meilleur contraste -->
            <div *ngIf="event.title.length <= 89 " class="text-right" style="margin-top: 2rem;">
              <span style="cursor: pointer; color: #A1C9C7;" (click)="onCardClick(event.url)">
                Voir plus <i class="fa-solid fa-circle-chevron-right fa-lg plus"></i> 
              </span>
            </div>
            <div *ngIf="event.title.length > 89 " class="text-right" style="margin-top: 1rem;">
              <span style="cursor: pointer; color: #A1C9C7;" (click)="onCardClick(event.url)">
                Voir plus <i class="fa-solid fa-circle-chevron-right fa-lg plus"></i> 
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="projects-section bg-light" id="contact">
  <div style="margin-bottom: 3rem">
    <h1
      class="text-center"
      style="
        font-family: Varela Round, -apple-system, BlinkMacSystemFont, Segoe UI,
          Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji,
          Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
        font-size: 3rem;
        line-height: 2.5rem;
        letter-spacing: 0.5rem;
        color: #23b1a5;
      "
    >
      Contactez-nous
    </h1>
    <hr
      class="text-primary custom-line"
      style="width: 10%; margin: auto; margin-top: 1.2rem"
    />
  </div>
  <div
    class="container px-4 px-lg-5"
    style="
      padding: 3rem;
      background-color: #f0f0f0;
      box-shadow: 0 8px 8px rgba(34, 34, 34, 0.03),
        0 4px 16px rgba(34, 34, 34, 0.1);
    "
  >
    <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
      <div class="col-md-6">
        <!-- Bloc d'informations de contact -->
        <div class="contact-info">
          <h2 style="color: #23b1a5">Informations de contact</h2>
          <br />
          <br />
          <p>
            <strong style="color: #23b1a5">Email:</strong
            ><a
              style="
                color: black !important;
                font-size: 1rem !important;
                text-decoration: none !important;
              "
              href="mailto:support@medivisto.com"
            >
              support@medivisto.com</a
            >
          </p>
          <!-- <p><strong style="color: #23b1a5;">Téléphone:</strong><a style="color: black !important; font-size: 1rem !important; text-decoration: none !important;" href="tel:+1234567890"> +1234567890</a></p> -->
        </div>
      </div>
      <div class="col-md-6">
        <!-- Bloc de formulaire de contact -->
        <form #f0="ngForm" [formGroup]="registerForm2">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="nom"
                ><strong style="color: #23b1a5; font-size: 1.2rem"
                  >Nom <b style="color: red">*</b></strong
                ></label
              >
              <input
                type="text"
                class="form-control"
                id="nom"
                placeholder="Nom"
                formControlName="nom"
                [ngClass]="{ 'is-invalid': submitted2 && f2.nom.errors }"
                onkeypress="return /[a-zA-Zéèçàâ ]/.test(event.key)"
              />
              <div *ngIf="submitted2 && f2.nom.errors" class="invalid-feedback">
                <div *ngIf="f2.nom.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f2.nom.errors.minlength">
                  Ce champ doit avoir minimum 3 caractères.
                </div>
                <div *ngIf="f2.nom.errors.maxlength">
                  Ce champ ne doit pas dépasser 25 caractères.
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="prenom"
                ><strong style="color: #23b1a5; font-size: 1.2rem"
                  >Prénom <b style="color: red">*</b></strong
                ></label
              >
              <input
                type="text"
                class="form-control"
                id="prenom"
                placeholder="Prénom"
                formControlName="prenom"
                [ngClass]="{ 'is-invalid': submitted2 && f2.prenom.errors }"
                onkeypress="return /[a-zA-Zéèçàâ ]/.test(event.key)"
              />
              <div
                *ngIf="submitted2 && f2.prenom.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f2.prenom.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f2.prenom.errors.minlength">
                  Ce champ doit avoir minimum 3 caractères.
                </div>
                <div *ngIf="f2.prenom.errors.maxlength">
                  Ce champ ne doit pas dépasser 25 caractères.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="email"
              ><strong style="color: #23b1a5; font-size: 1.2rem"
                >Email <b style="color: red">*</b></strong
              ></label
            >
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="E-mail"
              formControlName="email"
              [ngClass]="{ 'is-invalid': submitted2 && f2.email.errors }"
              onkeypress="return /[a-zA-Z0-9 .@]/.test(event.key)"
            />
            <div *ngIf="submitted2 && f2.email.errors" class="invalid-feedback">
              <div *ngIf="f2.email.errors.required">
                Ce champ est obligatoire.
              </div>
              <div *ngIf="f2.email.errors.email">Cet e-mail est invalide.</div>
            </div>
          </div>
          <div class="form-group">
            <label for="telephone"
              ><strong style="color: #23b1a5; font-size: 1.2rem"
                >Numéro de téléphone <b style="color: red">*</b></strong
              ></label
            >
            <br />
            <ngx-intl-tel-input
              [cssClass]="'form-control input-custom-phone'"
              formControlName="telephone"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Tunisia"
              [maxLength]="15"
              [phoneValidation]="true"
              name="telephone"
              [ngClass]="{ 'is-invalid': submitted2 && f2.telephone.errors }"
              onkeypress="return /[0-9]/.test(event.key)"
            ></ngx-intl-tel-input>
            <div
              *ngIf="submitted2 && f2.telephone.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f2.telephone.errors.required">
                Ce champ est obligatoire.
              </div>
            </div>
            <div
              *ngIf="
                registerForm2.value.telephone &&
                !f0.form.controls['telephone'].invalid == false
              "
              style="color: red"
            >
              Ce numéro de téléphone est invalide. <i class="bi bi-x"></i>
            </div>
            <div
              *ngIf="
                registerForm2.value.telephone &&
                !f0.form.controls['telephone'].invalid == true
              "
              style="color: green"
            >
              Ce numéro de téléphone est valide. <i class="bi bi-check"></i>
            </div>
          </div>
          <div class="form-group">
            <label for="message"
              ><strong style="color: #23b1a5; font-size: 1.2rem"
                >Message <b style="color: red">*</b></strong
              ></label
            >
            <textarea
              class="form-control"
              id="message"
              rows="3"
              placeholder="Votre message"
              formControlName="message"
              [ngClass]="{ 'is-invalid': submitted2 && f2.message.errors }"
            ></textarea>
            <div
              *ngIf="submitted2 && f2.message.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f2.message.errors.required">
                Ce champ est obligatoire.
              </div>
              <div *ngIf="f2.message.errors.maxlength">
                Ce champ peut comporter au maximum 200 caractères.
              </div>
            </div>
          </div>
          <div class="text-center mt-4">
            <button class="btn btn-add" (click)="onSubmit2()">Envoyer</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>

<!--modal-->
<div
  class="modal fade"
  id="exampleModalArchiver"
  #exampleModalArchiver
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel" style="color: #23b1a5">
          Demander une démo
        </h2>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
         
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #f00="ngForm" [formGroup]="registerForm">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="nom">Nom <b style="color: red">*</b></label>
              <input
                type="text"
                class="form-control"
                id="nom"
                placeholder="Nom"
                formControlName="nom"
                [ngClass]="{ 'is-invalid': submitted && f.nom.errors }"
                onkeypress="return /[a-zA-Zéèçàâ ]/.test(event.key)"
                />
              <div *ngIf="submitted && f.nom.errors" class="invalid-feedback">
                <div *ngIf="f.nom.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f.nom.errors.minlength">
                  Ce champ doit avoir minimum 3 caractères.
                </div>
                <div *ngIf="f.nom.errors.maxlength">
                  Ce champ ne doit pas dépasser 25 caractères.
                </div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label for="prenom">Prénom <b style="color: red">*</b></label>
              <input
                type="text"
                class="form-control"
                id="prenom"
                placeholder="Prénom"
                formControlName="prenom"
                [ngClass]="{ 'is-invalid': submitted && f.prenom.errors }"
                onkeypress="return /[a-zA-Zéèçàâ ]/.test(event.key)"
                />
              <div
                *ngIf="submitted && f.prenom.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.prenom.errors.required">
                  Ce champ est obligatoire.
                </div>
                <div *ngIf="f.prenom.errors.minlength">
                  Ce champ doit avoir minimum 3 caractères.
                </div>
                <div *ngIf="f.prenom.errors.maxlength">
                  Ce champ ne doit pas dépasser 25 caractères.
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email <b style="color: red">*</b></label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="E-mail"
              formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              onkeypress="return /[a-zA-Z0-9 .@]/.test(event.key)"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">
                Ce champ est obligatoire.
              </div>
              <div *ngIf="f.email.errors.email">Cet e-mail est invalide.</div>
            </div>
          </div>
          <div class="form-group">
            <label>Êtes vous un médecin ? <b style="color: red"> * </b></label>
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <input type="radio" formControlName="typeUser" [value]="true" />
                Oui
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <input
                  type="radio"
                  formControlName="typeUser"
                  [value]="false"
                />
                Non
              </div>
            </div>
          </div>
          <div *ngIf="registerForm.value.typeUser == true" class="form-group">
            <label
              >Quel est votre spécialité? <b style="color: red">*</b></label
            >
            <ng-select
              style="height: 50px"
              [items]="specialiites"
              class="form-control input-custom"
              formControlName="specialite"
              (change)="selectSpecilaite($event)"
              [(ngModel)]="selectedSpecialite"
              [closeOnSelect]="true"
            >
            </ng-select>
          </div>
          <div class="form-group">
            <label for="telephone"
              >Numéro de téléphone <b style="color: red">*</b></label
            >
            <br />
            <ngx-intl-tel-input
              [cssClass]="'form-control input-custom-phone'"
              formControlName="telephone"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Tunisia"
              [maxLength]="15"
              [phoneValidation]="true"
              name="telephone"
              [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }"
              onkeypress="return /[0-9]/.test(event.key)"
            ></ngx-intl-tel-input>
            <div
              *ngIf="submitted && f.telephone.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.telephone.errors.required">
                Ce champ est obligatoire.
              </div>
            </div>

            <div
              *ngIf="
                registerForm.value.telephone &&
                !f00.form.controls['telephone'].invalid == false
              "
              style="color: red"
            >
              Ce numéro de téléphone est invalide. <i class="bi bi-x"></i>
            </div>
            <div
              *ngIf="
                registerForm.value.telephone &&
                !f00.form.controls['telephone'].invalid == true
              "
              style="color: green"
            >
              Ce numéro de téléphone est valide. <i class="bi bi-check"></i>
            </div>
          </div>
          <div class="form-group">
            <label for="message"
              >Votre demande <b style="color: red">*</b></label
            >
            <textarea
              class="form-control"
              id="message"
              rows="3"
              placeholder="Votre demande"
              formControlName="message"
              [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
            >
            </textarea>
            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
              <div *ngIf="f.message.errors.required">
                Ce champ est obligatoire.
              </div>
              <div *ngIf="f.message.errors.maxlength">
                Ce champ peut comporter au maximum 200 caractères.
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer text-center">
        <button class="btn btn-add" (click)="onSubmit()">Envoyer</button>
      </div>
    </div>
  </div>
</div>
