<section class="contact-section bg-black">
  <app-cookies></app-cookies>
  <div class="container px-4 px-lg-5">
    <div class="row gx-4 gx-lg-5">
      <div class="col-lg-4 col-md-12 mb-5 mb-lg-0">
        <img
          src="../../../assets/img/medivisto-blanc.png"
          alt="Logo"
          style="max-width: 300px"
        />
      </div>
      <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
        <h4 class="text-white"><strong>Plan du site</strong></h4>
        <ul>
          <li><a href="#">Accueil</a></li>
          <li><a href="#localisation-medicale">Localisation médicale</a></li>
          <li><a href="#portes-vers-la-sante">Portes vers la santé</a></li>
          <li>
            <a href="#pourquoi-utiliser-medivisto"
              >Pourquoi utiliser Medivisto?</a
            >
          </li>
          <li><a href="#evenement">Évènements</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>
      <div class="col-lg-4 col-md-6 mb-5 mb-lg-0">
        <h4 class="text-white"><strong>Nous suivre</strong></h4>
        <div class="social d-flex">
          <a
            href="https://www.facebook.com/profile.php?id=61556574544218"
            target="_blank"
            class="mx-2"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            href="https://www.instagram.com/medivisto/"
            target="_blank"
            class="mx-2"
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            href="https://www.linkedin.com/company/medivisto/"
            target="_blank"
            target="_blank"
            class="mx-2"
            ><i class="fab fa-linkedin"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
  <br />
</section>
<footer class="footer small text-center text-white-50">
  <div class="container px-4 px-lg-5" style="color: white">
    <a
      href="../../../assets/medivisto-conditions-generales-utilisation.pdf"
      target="_blank"
      style="color: #fff; text-decoration: none"
      >Conditions générales d’utilisation</a
    >
    | &copy; Tous droits réservés {{ year }} | Conçu et développé par
    <a
      href="https://www.voguelconsulting.com/"
      target="_blank"
      style="color: #fff; text-decoration: none"
      >Voguel consulting</a
    >
  </div>
</footer>
