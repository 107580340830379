import { Component } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'projet';
  constructor(private recaptchaV3Service: ReCaptchaV3Service,private httpClient: HttpClient) {
  }
	separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
	phoneForm = new FormGroup({
		phone: new FormControl(undefined, [Validators.required])
	});
  ngOnInit(): void {
    this.verifyRecaptcha();
  }


	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}
  verifyRecaptcha() {
    this.recaptchaV3Service.execute(environment.recaptcha.siteKey)
      .subscribe((token: string) => {
        localStorage.setItem('reCAPTCHAToken',token)
      }, (error: any) => {
        console.error('reCAPTCHA error:', error);
      });
  }
}