import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { _getURL } from 'config/API_CONFIG';
import { EvenementV2Service } from 'src/app/services/evenement.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

const EVENT_KEY = makeStateKey<any>('event'); // Create a key for TransferState

@Component({
  selector: 'app-event-deatils',
  templateUrl: './event-deatils.component.html',
  styleUrls: ['./event-deatils.component.css'],
  providers:[EvenementV2Service,TransferState]
})
export class EventDeatilsComponent implements OnInit {
  eventTitle: string;
  event:any;
  baseImageUrl= _getURL('')+'data/files/evenement/'
  constructor(private titleService: Title, private metaService: Meta,private route: ActivatedRoute,private evenementsService:EvenementV2Service,private router:Router,@Inject(PLATFORM_ID) private platformId: Object ,
    private transferState: TransferState // Inject TransferState for SSR

  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      }
    this.eventTitle = this.route.snapshot.paramMap.get('title')!;
    this.baseImageUrl = this.baseImageUrl.replace("/api","");
    let eventtt = this.transferState.get(EVENT_KEY, null);

    if (eventtt) {
      this.setTitleAndMetaTags(eventtt); // Use SSR data if available
    }else{
      if(this.eventTitle){
        this.evenementsService.getAllEventByUrl(this.eventTitle).subscribe(
          (response) => {
            this.event = response; // Store the fetched events
            if(this.event){
              this.setTitleAndMetaTags(this.event)
            }else{
              this.router.navigate(['/'])
            }
          },
          (error) => {
            this.router.navigate(['/'])        
            }
        );
      }else{
        this.router.navigate(['/'])
      }
    }
    
  }
  setTitleAndMetaTags(eventtt: any): void {
    // Set the page title
    this.titleService.setTitle(eventtt.metaTitle);

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content:eventtt.metaDescription
    });

    // Set meta keywords
    this.metaService.updateTag({
      name: 'keywords',
      content:eventtt.metaKeywords
    });
  }
}
