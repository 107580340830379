export const API_CONFIG = {
  apiPathBase: '/',
 //apiPathBase: 'http://localhost:3013/',
 article:'api/article/',
 contact:'api/contact/',
 uploadArticle:'data/files/articles',
 recaptchaverification:'api/verify-recaptcha',
 medecin:'api/medecin',
 pharmacie:'api/pharmacie',
 patient:'api/users',
 authenticateMedecin:'api/authenticate/medecin/new/version',
 authenticateMedecinCode:'api/authenticate/medecin/new/version/code',
 evenement:'api/evenements'

};
export function _getURL(serviceName){
 return API_CONFIG.apiPathBase + serviceName;
}

// export const ophtalmoLink = "http://localhost:4200"
// export const othersLink = "http://localhost:4200"

export const ophtalmoLink = "https://ophtalmo.medivisto.com"
export const othersLink = "https://connect.medivisto.com"
export const patientLink = "https://patient.medivisto.com/connexion"



