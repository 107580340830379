<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="container px-4 px-lg-5 d-flex align-items-center justify-content-center">
        <div class="card col-lg-6 col-md-8 col-sm-10 p-5">
            <h2 class="text-center">Connexion</h2>
            <br>
            <div class="text-center">
                <a class="mr-4" [ngClass]="{ 'medecin-link': isMedecin }" (click)="showMedecinForm()">Vous êtes un médecin?</a>
                <a  [ngClass]="{ 'patient-link': isPatient }" (click)="showPatientForm()" >Vous êtes un patient?</a>
            </div>
            <form  *ngIf="isPatient">
                <!-- Formulaire pour le patient -->
                <br>
                <div class="text-center">
                    <h2 class="text-center" style="color: #A1C9C7;"><strong>En cours de construction</strong></h2>            
                    <br>
                    <i class="fa-solid fa-person-digging" style="font-size: 8rem; color: #A1C9C7;"></i>
                </div>
            </form>

            <!-- Formulaire pour le médecin -->
            <form [formGroup]="medecinForm" (ngSubmit)="onMedecinSubmit()" *ngIf="isMedecin && !showCodeInterface">
                <br>
                <p *ngIf="counterClick>4" id="alert-msg-nombre-tentative">Ce mot de passe est incorrect.Nous pouvons vous aider à <a  style="text-decoration: underline;cursor: pointer;font-size: 14px !important;" routerLink="/mot-de-passe-oublie/medecin">le récupérer</a> <br> 
                        Pour des raisons de sécurité, après <b>10</b> tentatives de connexion, vous devrez attendre <b>30</b> minutes avant de réessayer<br>
                    </p>
                <div class="form-group">
                    <label for="email">Numéro d'inscription <b style="color: red">*</b></label>
                    <input type="text" class="form-control" placeholder="Numéro d'inscription"
                        formControlName="numInscription" [ngClass]="{ 'is-invalid': submitted && f2.numInscription.errors }" onkeypress="return /[a-zA-Z0-9]/.test(event.key)"/>
                        <div *ngIf="submitted && f2.numInscription.errors" class="invalid-feedback">
                            <div *ngIf="f2.numInscription.errors.required">
                              Ce champ est obligatoire.
                            </div>
			    <div *ngIf="f2.numInscription.errors.minlength">
                            Ce champ doit avoir au minimum 4 chiffres.
                        </div>
                            <div *ngIf="f2.numInscription.errors.maxlength">
                              Le numéro doit contenir maximum 11 chiffres.
                          </div>
                        
                          </div>
                </div>
                <div class="form-group">
                    <label for="email">Email <b style="color: red">*</b></label>
                    <input type="email" class="form-control" id="email" placeholder="E-mail"
                        formControlName="email"  [ngClass]="{ 'is-invalid': submitted && f2.email.errors }"  onkeypress="return /[a-zA-Z0-9 .@]/.test(event.key)"/>
                    <div *ngIf="submitted && f2.email.errors" class="invalid-feedback">
                        <div *ngIf="f2.email.errors.required">
                            Ce champ est obligatoire.
                        </div>
                        <div *ngIf="f2.email.errors.email">
                            Cet e-mail est invalide.
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">Mot de passe <b style="color: red">*</b></label>
                    <input type="password" class="form-control" id="password" placeholder="Mot de passe"
                        formControlName="password" [ngClass]="{ 'is-invalid': submitted && f2.password.errors }" />
                    <div *ngIf="submitted && f2.password.errors" class="invalid-feedback">
                        <div *ngIf="f2.password.errors.required">
                            Ce champ est obligatoire.
                        </div>
                    </div>
                </div>
                <br>
                <div class="text-center">
                    <button type="submit" class="btn btn-connexion" [attr.disabled]="!isValid ? true : null">Se connecter</button>
                </div>
                <div class="text-center mt-3">
                    <span><a routerLink="/mot-de-passe-oublie/medecin" style="text-decoration: none;font-size: 1rem !important;">Mot de passe oublié?</a> ou <a routerLink="/inscription/medecin" style="text-decoration: none;font-size: 1rem !important;">Inscrivez-vous</a></span>
                </div>
            </form>

            <form [formGroup]="medecinFormCode" (ngSubmit)="onMedecinSubmitCode()" *ngIf="isMedecin && showCodeInterface">
               
                <div class="form-group">
                    <br>
                    <label for="email">Code de validation <b style="color: red">*</b></label>
                    <input type="text" class="form-control" placeholder="Code de validation"
                        formControlName="codeValidation" [ngClass]="{ 'is-invalid': submitted && f3.codeValidation.errors }" onkeypress="return /[a-zA-Z0-9]/.test(event.key)"/>
                        <div *ngIf="submitted && f3.codeValidation.errors" class="invalid-feedback">
                            <div *ngIf="f3.codeValidation.errors.required">
                              Ce champ est obligatoire.
                            </div>
                            <div *ngIf="f3.codeValidation.errors.maxlength">
                              Le numéro doit contenir maximum 11 chiffres.
                          </div>
                        
                          </div>
                </div>
                
                <div class="text-center">
                    <button type="submit" class="btn btn-connexion" [attr.disabled]="!isValid ? true : null">Se connecter</button>
                </div>
            </form>
        </div>
    </div>
</header>
<app-footer></app-footer>
