import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG,_getURL } from 'config/API_CONFIG';
@Injectable({
  providedIn: 'root'
})
export class ContactMarketingService {
  contactMarketing :any []=[];
  private contactUrl=_getURL(API_CONFIG.contact) 
  constructor(private http: HttpClient) { }

      createDemo(demo : any):Observable<Object> {
        return this.http.post(`${this.contactUrl}demo`,demo);
      }

      createContact(demo : any):Observable<Object> {
        return this.http.post(`${this.contactUrl}contact`,demo);
      }


}
