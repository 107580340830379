<app-navbar></app-navbar>

<main class="content-wrapper mt-5">
    <section class="event-details-section" itemscope itemtype="https://schema.org/MedicalEvent">
        <div *ngIf="event" class="container">
            <div class="row justify-content-center mb-5">

                <div class="col-12 col-md-8 position-relative text-center">

                    <a  target="_blank">
                        <img [src]="baseImageUrl + event.key + '/'+ event.image" alt="Event Image"
                            class="img-fluid rounded shadow" itemprop="image"
                            style="width: 100%; height: auto; object-fit: cover; max-height: 500px;">
                    </a>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-12 col-md-10">
                    <div class="d-flex justify-content-between align-items-center">
                        <h6 style="color: #23b1a5 ;" class="text-justify mt-4"><b>{{event.title}}</b></h6>
                        <p style="color: #A1C9C7;" class="event-date mb-0"><i>{{event.dateDebut  | date: 'dd/MM/yyyy' }}</i></p>
                    </div>
                </div>
                <div class="col-12 col-md-10">
                    <p itemprop="description" class="text-justify mt-4" [innerHTML]="event.description">
                    </p>


                    <div itemprop="location" itemscope itemtype="https://schema.org/Place">
                        <meta itemprop="name" content="Event Location Name">
                        <meta itemprop="address" content="123 Event Street, City, Country">
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>

<app-footer></app-footer>