import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup , Validators} from '@angular/forms';

@Component({
  selector: 'app-mot-de-passe-oublie-patient',
  templateUrl: './mot-de-passe-oublie-patient.component.html',
  styleUrls: ['./mot-de-passe-oublie-patient.component.css']
})
export class MotDePasseOubliePatientComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  constructor(private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
  });
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
        return;
    }
}
}
