import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MedecinService } from '../../services/medecin.service';
import { Router,ActivatedRoute } from '@angular/router';
import { API_CONFIG, _getURL, ophtalmoLink, othersLink } from 'config/API_CONFIG';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MustMatch } from 'src/app/helpers/must-match.validator';

@Component({
  selector: 'app-mot-de-passe-initialiser-medecin',
  templateUrl: './mot-de-passe-initialiser-medecin.component.html',
  styleUrls: ['./mot-de-passe-initialiser-medecin.component.css','../mot-de-passe-oublie-medecin/mot-de-passe-oublie-medecin.component.css']
})
export class MotDePasseInitialiserMedecinComponent implements OnInit {

 registerForm: FormGroup;
  submitted: boolean = false;
  isValid = true;
  params:any;
  constructor(private formBuilder: FormBuilder, private medecinService: MedecinService,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service, public iziToast: Ng2IzitoastService,
    private http: HttpClient, private route: ActivatedRoute,) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
      confirmationPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
    }, {
      validator: MustMatch('password', 'confirmationPassword')
    });
    this.route.params.subscribe(params => {
      this.params = params; // Access the 'id' parameter from the URL
    });
    if (!this.params) {
      this.router.navigate(['']);
      return false;
    }
    
  }
  get f() { return this.registerForm.controls; }
  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.http.post(_getURL(API_CONFIG.recaptchaverification), { token: token })
          .subscribe((response: any) => {
            if (response && response.success && response.score >= 0.5) {
              this.suivreResetPassword()
              return true;
            } else {
              this.isValid = false
              this.iziToast.show({
                message: "Erreur de recaptcha",
                messageColor: '#800f2f',
                titleColor: '#800f2f',
                progressBarColor: '#c9184a',
                icon: 'bi-exclamation-diamond',
                imageWidth: 45,
                position: 'topRight',
                timeout: 5000,
                backgroundColor: '#ff8fa3',
                transitionIn: 'flipInX',
                transitionOut: 'flipOutX',
                // overlay: true,
                // overlayClose: true,
              });
              setTimeout(() => {
                  this.isValid = true
                },
                5000);
              return false;
            }
            // Handle the verification response from the backend
          });

      }
    }, error => {
      this.isValid = false
      this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    });
  }
  suivreResetPassword() {
    if(!this.params){
      return false;
    }
    if(this.params && (!this.params.restoken || this.params.restoken== null || this.params.restoken== "null" || this.params.restoken== undefined || this.params.restoken== "undefined" || this.params.restoken.length > 40)){
      return false;
    }
    if (this.registerForm.value.password == undefined || this.registerForm.value.password === '' || this.registerForm.value.confirmationPassword == undefined || this.registerForm.value.confirmationPassword === '') {
      this.isValid = false
      this.iziToast.show({
        message: "Ajouter votre mot de passe",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
          this.isValid = true
        },
        5000);
      return false;
    }
    this.medecinService.resetPassword(this.registerForm.value,this.params.restoken).subscribe((data: any) => {
        if (data && data.success) {
          this.iziToast.show({
            message: "Votre mot de passe a été modifié avec succès",
            messageColor: '#21726B',
            progressBarColor: '#21726B',
            imageWidth: 45,
            position: 'topRight',
            timeout: 8000,
            backgroundColor: '#e7f5f5',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
          })
          this.router.navigate(['connexion']);
          return true;
        } else {
          this.isValid = false
          let msg = ""
          if (data) {
            msg = data.msg
            this.iziToast.show({
              message: msg,
              messageColor: '#800f2f',
              titleColor: '#800f2f',
              progressBarColor: '#c9184a',
              icon: 'bi-exclamation-diamond',
              imageWidth: 45,
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              // overlay: true,
              // overlayClose: true,
            });
            setTimeout(() => {
                this.isValid = true
                this.router.navigate(['mot-de-passe-oublie/medecin']);
              },
              5000);
          }
        }
      },
      err => {
        return false;
      });



  }
}
