<app-navbar></app-navbar>
<section class="projects-section bg-light" id="portes-vers-la-sante">
    <div style="margin-bottom: 3rem;"></div>
    <div class="container">
        <div class="row justify-content-center" *ngIf="medecins && medecins.length > 0">
            <div class="col-8 mb-4" *ngFor="let medecin of medecins">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Dr {{ medecin.nom }} {{ medecin.prenom }}</h5>
                        <p class="card-text">
                            <span class="label"><i class="fas fa-stethoscope"></i> Spécialité: </span> 
                            <span class="badge bg-color badge-responsive">{{ medecin.specialite }}</span>
                        </p>
                        <p class="card-text">
                            <span class="label"><i class="fa-solid fa-location-dot"></i> Adresse: </span> 
                            <span>{{ medecin.adresseCabinet.adresse }}   <b style="color: #21726B;"></b></span> 
                        </p>
                        <p class="card-text">
                            <span class="label"><i class="fa-solid fa-map-location"></i> Ville: </span> 
                            <span>{{medecin.adresseCabinet.localite}}, {{ medecin.adresseCabinet.ville }}</span> 
                        </p>
                        <a style="color: #fff;flxoat: right;" (click)="getDirections(medecin)" class="btn btn-connexion"><i class="fa-solid fa-location-crosshairs"></i> Itinéraire</a>
                    </div>
                </div>
                
            </div>
        </div>
        <div *ngIf="totalPages > 1" class="row justify-content-center">
            <div class="col-auto">
                <button class="btn btn-primary" (click)="previousPage()" [disabled]="currentPage === 1"><i class="fas fa-chevron-left"></i></button>
            </div>
            <div class="col-auto">
                <div class="btn-group">
                    <button class="btn btn-outline-primary" *ngFor="let page of getPageNumbers()" [class.active]="page === currentPage" (click)="goToPage(page)">{{ page }}</button>
                </div>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary" (click)="nextPage()" [disabled]="currentPage === totalPages"><i class="fas fa-chevron-right"></i></button>
            </div>
        </div>
        <div *ngIf="totalPages == 0">
            <div class="card">
                <div class="card-body">
                    <p class="card-text-center" style="text-align: center;padding-bottom: 23px;padding-top: 45px;">
                        <span style="color: #21726B;"><b>Aucun médecin trouvé.</b></span>
                    </p>
                </div>
        </div>
        </div>
    </div>
</section>
<app-footer></app-footer>

<ngx-spinner *ngIf="medecins && medecins.length > 0" type="square-jelly-box">
	<p style="font-size: 20px; color: white">Veuillez patienter ...</p>
</ngx-spinner>
