<nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
    <div class="container px-4 px-lg-5 d-flex justify-content-between align-items-center">
        <a class="navbar-brand" href="#accueil">
            <img src="../../../assets/img/logo.png" alt="Logo" class="img-fluid" style="max-width: 175px;">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0"> <!-- Modification de la classe ici -->
                <li class="nav-item"><a class="nav-link" href="#localisation-medicale">Localisation médicale</a></li>
                <li class="nav-item"><a class="nav-link" href="#portes-vers-la-sante">Portes vers la santé</a></li>
                <li class="nav-item"><a class="nav-link" href="#pourquoi-utiliser-medivisto">Pourquoi utiliser Medivisto?</a></li>
                <li class="nav-item"><a class="nav-link" href="#evenement">Évènements</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
            </ul>
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a routerLink="/connexion" class="btn btn-connexion" >Connexion</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
