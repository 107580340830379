import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { API_CONFIG } from '../../../config/API_CONFIG';
import { _getURL } from '../../../config/API_CONFIG';
import { Observable } from 'rxjs';

@Injectable()
export class ArticleService {

    constructor(private http:HttpClient) { }
    listArticle():Observable<any[]>
    {
      return this.http.get<any[]>(_getURL(API_CONFIG.article)+"getAllArticle");
    }
    listArticleNonArchive():Observable<any[]>
    {
      return this.http.get<any[]>(_getURL(API_CONFIG.article)+"getAllArticleNonArchived");
    }
  
    findArticleById(id:String):Observable<any>
    {
      return this.http.get<any>(_getURL(API_CONFIG.article)+"findArticleById"+'/'+id);
    }
  
  
  
  
  }
  
