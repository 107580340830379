<app-navbar></app-navbar>
<header class="masthead" id="accueil">
    <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
        <div class="card col-lg-12 col-md-8 col-sm-10 p-5">
      <br><br><br>
      <div class="text-center">
        <h2 class="text-center" style="color: #A1C9C7;"><strong>En cours de construction</strong></h2>
        <br>
        <i class="fa-solid fa-person-digging" style="font-size: 8rem; color: #A1C9C7;"></i>
      </div>
    </div>
    </div>
</header>
<app-footer></app-footer>
