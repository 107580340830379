<div id="cookie-popup" *ngIf="!cookieAccepted">
  <p>Cookies</p>
  <p>
    En cliquant sur « Accepter tous les cookies », vous acceptez le stockage de
    cookies sur votre appareil pour améliorer la navigation sur le site,
    analyser son utilisation et contribuer à nos efforts de marketing.
  </p>
  <button class="cookie-popup__button" (click)="acceptCookies()">
    Autoriser tous les cookies
  </button>
  <button
    style="margin-top: 5%; margin-bottom: 4%"
    class="cookie-popup__button"
    (click)="refusCookies()"
  >
    Tout refuser
  </button>
  <a
    style="font-size: 13px; text-decoration: underline; cursor: pointer"
    data-toggle="modal"
    data-target="#exampleModalCoockiesSettings"
    >Paramètres des cookies</a
  >
</div>

<!--modal-->
<div
  class="modal fade"
  id="exampleModalCoockiesSettings"
  #exampleModalArchiver
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <img
          width="25%"
          src="../../../assets/img/logo.png"
          alt="Logo"
          class="img-fluid"
          style="margin-left: 7%"
        />
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h2 style="font-weight: bold; font-size: 1rem; color: #696969">
          Centre de préférences de la confidentialité
        </h2>
        <br />
        <p
          style="
            clear: both;
            width: 100%;
            font-size: 0.812em;
            line-height: 1.8;
            margin-bottom: 27px;
          "
        >
          Lorsque vous consultez un site Web, des données peuvent être stockées
          dans votre navigateur ou récupérées à partir de celui-ci, généralement
          sous la forme de cookies. Ces informations peuvent porter sur vous,
          sur vos préférences ou sur votre appareil et sont principalement
          utilisées pour s'assurer que le site Web fonctionne correctement. Les
          informations ne permettent généralement pas de vous identifier
          directement, mais peuvent vous permettre de bénéficier d'une
          expérience Web personnalisée. Parce que nous respectons votre droit à
          la vie privée, nous vous donnons la possibilité de ne pas autoriser
          certains types de cookies. Toutefois, si vous bloquez certains types
          de cookies, votre expérience de navigation et les services que nous
          sommes en mesure de vous offrir peuvent être impactés.
        </p>
      </div>
      <div
        class="modal-footer text-start"
        style="display: flex; justify-content: flex-end"
      >
        <button
          (click)="refusCookies()"
          data-dismiss="modal"
          class="btn"
          style="
            color: white;
            height: 50px;
            background-color: #23b1a5;
            margin-left: 10px;
          "
        >
          Tout refuser
        </button>
        <button
          (click)="acceptCookies()"
          data-dismiss="modal"
          class="btn"
          style="color: white; height: 50px; background-color: #23b1a5"
        >
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
