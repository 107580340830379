import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MedecinService } from 'src/app/services/medecin.service';
import {  Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-medecin',
  templateUrl: './medecin.component.html',
  styleUrls: ['./medecin.component.css']
})
export class MedecinComponent implements OnInit {
  medecins: any;
  nomMedecin: any;
  specialiteMedecin: any;
  villeMedecin: any;
  currentPage: number = 1;
  totalPages: number = 0;
  totalPagesArray: number[] = [];
  specialiites = ["Anatomie Pathologique", "Anesthesie & Anesthesie Reanimation", "Bacteriologie & Microbiologie",
    "Biochimie", "Biologie Clinique", "Biophysique Et Medecine Nucleaire", "Cardiologie", "Chirurgie Cancérologie",
    "Chirurgie Cardio-Vasculaire & Peripherique", "Chirurgie Generale", "Chirurgie Orthopedique", "Chirurgie Pediatrique",
    "Chirurgie Plastique & Reparatrice", "Dermatologie", "Endocrinologie", "Gastro-Enterologie", "Gynecologie-Obstetrique",
    "Hematologie Biologique", "Hematologie Clinique", "Histo-Embryologie", "Immunologie & Virologie", "Maladies Infectieuses",
    "Medecin Dentiste", "Medecine Cancérologique", "Medecine D'Urgence", "Medecine De Famille", "Medecine Du Travail", "Medecine Generale",
    "Medecine Interne", "Medecine Physique", "Nephrologie", "Neuro-Chirurgie", "Neurologie", "Nutrition", "O.R.L.", "Ophtalmologie",
    "Orthopedie Dento Faciale", "Pediatrie", "Physiologie & Exploration Fonctionnelle", "Physiotherapie", "Pneumo-Phthisiologie", "Psychiatrie",
    "Psychiatrie Infantile", "Radiologie (Diagnostic)", "Radiotherapie", "Reanimation Medicale", "Rhumatologie", "Stomatologie Et Chirurgie Maxillo-Faciale",
    "Urologie"
  ]


  villesTunisiennes = [
    "Tunis", "Ariana", "Ben Arous", "Manouba", "Nabeul", "Zaghouan",
    "Bizerte", "Béja", "Jendouba", "Le Kef", "Siliana", "Kairouan",
    "Kasserine", "Sidi Bouzid", "Sousse", "Monastir", "Mahdia", "Sfax",
    "Gabès", "Médenine", "Tataouine", "Gafsa", "Tozeur", "Kebili"
  ];
  constructor(private titleService: Title, private metaService: Meta,@Inject(PLATFORM_ID) private platformId: Object,private medecinService: MedecinService, private route: ActivatedRoute,private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.setTitleAndMetaTags()
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 2000); // 2 seconds
    this.route.queryParams.subscribe(params => {
      this.nomMedecin = params['prenom_nom'];
      this.specialiteMedecin = params['specialite'];
      this.villeMedecin = params['localisation'];
      if (this.villesTunisiennes.includes(this.villeMedecin) || this.specialiites.includes(this.villeMedecin)) {
        this.medecins = []
        //return true;
      }

      if(this.villeMedecin && this.villeMedecin != undefined && this.villeMedecin.indexOf("é") > -1 ){
        this.villeMedecin = this.villeMedecin.replace(/é/g, 'e');}
      if(this.villeMedecin && this.villeMedecin != undefined && this.villeMedecin.indexOf("è") > -1 ){
        this.villeMedecin = this.villeMedecin.replace(/è/g, 'e');}  
      this.searchMedecins();
    });
  }
  setTitleAndMetaTags(): void {
    // Set the page title
    this.titleService.setTitle('Medivisto - Recherche Médecins');

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content:"Trouvez facilement des médecins en Tunisie avec notre plateforme médicale Medivisto. Recherchez par spécialité, localisation, ou nom pour trouver le professionnel de santé qui répond à vos besoins. Accédez à des profils détaillés et prenez rendez-vous en ligne."

    });

    // Set meta keywords
    this.metaService.updateTag({
      name: 'keywords',
      content:"recherche médecins, trouver médecin, plateforme médicale, médecins Tunisie, annuaire médical, consultation en ligne, recherche spécialiste, services médicaux, soins de santé Tunisie, trouver un médecin"
    });
  }
  searchMedecins() {
    if(this.nomMedecin && this.nomMedecin.length > 50){
      this.medecins = []
      return false;
    }
    if(this.specialiteMedecin && this.specialiites.indexOf(this.specialiteMedecin) === -1){
      this.medecins = []
      return false;
    }
    if(this.villeMedecin && this.villesTunisiennes.indexOf(this.villeMedecin) === -1){
      this.medecins = []
      return false;
    }
    this.medecinService.searchMedecin(this.nomMedecin, this.specialiteMedecin, this.villeMedecin, this.currentPage)
        .subscribe(
            (data: any) => {
                this.medecins = data.medecins;
                this.totalPages = data.totalPages;
                this.totalPagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
            },
            (error) => {
                return false
            }
        );
}

  nextPage(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      }
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.searchMedecins();
    }
  }

  previousPage(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      }
    if (this.currentPage > 1) {
      this.currentPage--;
      this.searchMedecins();
    }
  }
  goToPage(page: number): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      }
    this.currentPage = page;
    this.searchMedecins();
  }
  ngAfterViewInit(): void {
    // Faire défiler la fenêtre vers le haut au chargement du composant
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      }
  }
  getPageNumbers(): number[] {
    const numButtonsToShow = 0; // Nombre de boutons de page à afficher
    let start = 1;
    let end = this.totalPages;

    if (this.totalPages > numButtonsToShow) {
        if (this.currentPage <= Math.ceil(numButtonsToShow / 2)) {
            start = 1;
            end = numButtonsToShow;
        } else if (this.currentPage >= this.totalPages - Math.floor(numButtonsToShow / 2)) {
            start = this.totalPages - numButtonsToShow + 1;
            end = this.totalPages;
        } else {
            start = this.currentPage - Math.floor(numButtonsToShow / 2);
            end = this.currentPage + Math.floor(numButtonsToShow / 2);
        }
    }

    // Vérifier si la première page est réellement la première dans la liste
    if (start > 1) {
        start = Math.max(1, start - 1); // Réduire d'un pour inclure la première page
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
}

getDirections(medecin: any): void {
  // Récupérer l'adresse du médecin
  const adresseMedecin = `${medecin.adresseCabinet.adresse}, ${medecin.adresseCabinet.localite}, ${medecin.adresseCabinet.ville}`;

  // Vérifier si le navigateur prend en charge la géolocalisation
  if (navigator.geolocation) {
    // Demander la position de l'utilisateur
    navigator.geolocation.getCurrentPosition((position) => {
      // Récupérer les coordonnées géographiques de l'utilisateur
      const userLatitude = position.coords.latitude;
      const userLongitude = position.coords.longitude;

      // Construire l'URL pour les directions
      const directionsURL = `https://www.google.com/maps/dir/${userLatitude},${userLongitude}/${encodeURIComponent(adresseMedecin)}`;
      
      // Ouvrir les directions dans un nouvel onglet
      window.open(directionsURL, '_blank');
    }, (error) => {
      console.error('Erreur de géolocalisation:', error);
      // En cas d'erreur, ouvrir les directions dans un nouvel onglet sans la position actuelle de l'utilisateur
      const directionsURL = `https://www.google.com/maps/dir//${encodeURIComponent(adresseMedecin)}`;
      window.open(directionsURL, '_blank');
    });
  } else {
    // Si la géolocalisation n'est pas prise en charge, ouvrir les directions dans un nouvel onglet sans la position actuelle de l'utilisateur
    const directionsURL = `https://www.google.com/maps/dir//${encodeURIComponent(adresseMedecin)}`;
    window.open(directionsURL, '_blank');
  }
}


// getDirections(medecin: any): void {
//   // Récupérer l'adresse du médecin
//   const adresseMedecin = `${medecin.adresse}, ${medecin.localite}, ${medecin.gouvernorat}`;

//   // Rediriger l'utilisateur vers Google Maps avec l'adresse du médecin
//   window.location.href = `https://www.google.com/maps/dir//${encodeURIComponent(adresseMedecin)}`;
// }

}
