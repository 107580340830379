import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactMarketingService } from 'src/app/services/contact-marketing.service';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MedecinService } from 'src/app/services/medecin.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ArticleService } from 'src/app/services/article.service';
import { API_CONFIG, _getURL } from '../../../../config/API_CONFIG';
import {  Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { EvenementV2Service } from 'src/app/services/evenement.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers:[EvenementV2Service]
})
export class HomeComponent implements OnInit {

  typesUsers = ["Médecin", "Patient"]

  specialiites = ["Anatomie Pathologique", "Anesthesie & Anesthesie Reanimation", "Bacteriologie & Microbiologie",
    "Biochimie", "Biologie Clinique", "Biophysique Et Medecine Nucleaire", "Cardiologie", "Chirurgie Cancérologie",
    "Chirurgie Cardio-Vasculaire & Peripherique", "Chirurgie Generale", "Chirurgie Orthopedique", "Chirurgie Pediatrique",
    "Chirurgie Plastique & Reparatrice", "Dermatologie", "Endocrinologie", "Gastro-Enterologie", "Gynecologie-Obstetrique",
    "Hematologie Biologique", "Hematologie Clinique", "Histo-Embryologie", "Immunologie & Virologie", "Maladies Infectieuses",
    "Medecin Dentiste", "Medecine Cancérologique", "Medecine D'Urgence", "Medecine De Famille", "Medecine Du Travail", "Medecine Generale",
    "Medecine Interne", "Medecine Physique", "Nephrologie", "Neuro-Chirurgie", "Neurologie", "Nutrition", "O.R.L.", "Ophtalmologie",
    "Orthopedie Dento Faciale", "Pediatrie", "Physiologie & Exploration Fonctionnelle", "Physiotherapie", "Pneumo-Phthisiologie", "Psychiatrie",
    "Psychiatrie Infantile", "Radiologie (Diagnostic)", "Radiotherapie", "Reanimation Medicale", "Rhumatologie", "Stomatologie Et Chirurgie Maxillo-Faciale",
    "Urologie"
  ]


  villesTunisiennes = [
    "Tunis", "Ariana", "Ben Arous", "Manouba", "Nabeul", "Zaghouan",
    "Bizerte", "Béja", "Jendouba", "Le Kef", "Siliana", "Kairouan",
    "Kasserine", "Sidi Bouzid", "Sousse", "Monastir", "Mahdia", "Sfax",
    "Gabès", "Médenine", "Tataouine", "Gafsa", "Tozeur", "Kebili"
  ];
  isOpenMedecinSelect: boolean = true;
  typesPharmacies = ["Jour", "Nuit"]
  nomMedecin: any;
  nomMedecinInSelect :any
  specialiteMedecin: any;
  villeMedecin: any
  card1Visible = false;
  card2Visible = false;
  card3Visible = false;
  card4Visible = false;
  card5Visible = false;
  card6Visible = false;
  card1TitleVisible = true;
  card2TitleVisible = true;
  card3TitleVisible = true;
  card4TitleVisible = true;
  card5TitleVisible = true;
  card6TitleVisible = true;

  country = 'BE'
  countryCode = "32";
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

  selectedOption: string = '';
  @ViewChild('selectMedecin') selectMedecin: ElementRef;
  @ViewChild('exampleModalArchiver') modal: any;
  registerForm: FormGroup;
  registerForm2: FormGroup
  registerForm3: FormGroup
  submitted = false;
  submitted2: boolean;
  submitted3: boolean;
  selectedSpecialite: string;
  medecins: any;
  nomPharmacie: any;
  typePharmacie: any;
  villePharmacie: any;
  tokenGenetate: string;
  randomItems: any[] = [];
  baseUrl=_getURL(API_CONFIG.uploadArticle)
  currentPage: number = 1;
  totalPages: number = 0;
  baseImageUrl= _getURL('')+'data/files/evenement/'
  events:any=[]=[]
  constructor(
    private formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object,
    private contactService: ContactMarketingService,
    private router: Router,
    public iziToast: Ng2IzitoastService,
    private medecinService: MedecinService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private articleService: ArticleService,
    private httpClient: HttpClient,
    private titleService: Title, private metaService: Meta,
    private evenementsService:EvenementV2Service
  ) {

  }
  ngOnInit(): void {
    this.baseImageUrl = this.baseImageUrl.replace("/api","");
    this.setTitleAndMetaTags();
    this.selectedSpecialite = this.specialiites[27];
    this.registerForm = this.formBuilder.group(
      {
        nom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        prenom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        typeUser: [false],
        specialite: [],
        telephone: ['', Validators.required],
        message: ['', [Validators.required, Validators.maxLength(200)]],
        token: [''],
      },
      {}
    );
    this.registerForm2 = this.formBuilder.group(
      {
        nom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        prenom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        telephone: ['', Validators.required],
        message: ['', [Validators.required, Validators.maxLength(200)]],
        token: [''],
      },
      {}
    );
    this.selectedOption = 'medecin';
    this.getEvents();

  }
  get f() { return this.registerForm.controls; }
  get f2() { return this.registerForm2.controls; }
 
  getEvents() {
    this.evenementsService.getAllEvents().subscribe(
      (response) => {
        this.events = response; // Store the fetched events
      },
      (error) => {
        console.error('Error fetching events:', error);
      }
    );
  }
  setTitleAndMetaTags(): void {
    // Set the page title
    this.titleService.setTitle('Medivisto - Accueil');

    // Set meta description
    this.metaService.updateTag({
      name: 'description',
      content:"Medivisto est une plateforme médicale qui permet une meilleure gestion et un bon suivi des dossiers médicaux tout en simplifiant le partage d’information entre le patient et le médecin."

    });

    // Set meta keywords
    this.metaService.updateTag({
      name: 'keywords',
      content:'médecine, santé, médecins Tunisie, hôpitaux Tunisie, soins médicaux, consultations médicales, spécialités médicales, clinique Tunisie, pharmacie Tunisie, télémédecine, prise de rendez-vous, chirurgie, diagnostic médical, traitement, assistance médicale, Tunisie santé, ophtalmologie, dentaire'
    });
  }
  onSubmit() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    //this.verifyRecaptcha();
    this.registerForm.value.telephone = this.registerForm.value.telephone.internationalNumber
    if (this.registerForm.value.typeUser == false) {
      this.registerForm.value.typeUser = "Patient"
      this.registerForm.value.specialite = ""
    } else {
      this.registerForm.value.typeUser = "Médecin"
    }


    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
        this.tokenGenetate = token
        this.registerForm.value.token = this.tokenGenetate
        this.contactService.createDemo(this.registerForm.value).subscribe(
      (data) => {
        this.verifyRecaptcha()
        if (this.modal) {
          this.modal.nativeElement.querySelector('.close').click();
        }
        //this.onReset()
        this.iziToast.show({
          message: 'Votre demande de démo a été bien envoyée!',
          messageColor: '#21726B',
          progressBarColor: '#21726B',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#e7f5f5',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
        })
        this.submitted = false;
        this.registerForm.reset();
        this.initFirstForm()
      },
      (error) => {
        this.verifyRecaptcha()
        this.submitted = false;
        this.registerForm.reset();
        this.iziToast.show({
          message: 'Une erreur est survenue. Merci de saisir vos données!',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          icon: 'bi-exclamation-diamond',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          // overlay: true,
          // overlayClose: true,
        });
        return;
        //this.modal.nativeElement.querySelector('.close').click();

      }

    );  
      }else{
        this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
        },
        5000);
      return false; 
    }
    }, error => {
      this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
        },
        5000);
      return false;
    });
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
  zoomIn(event: any): void {
    event.target.style.transform = 'scale(1.2)';
  }

  zoomOut(event: any): void {
    event.target.style.transform = 'scale(1)';
  }
  toggleCard(cardNumber: number) {
    switch (cardNumber) {
      case 1:
        this.card1Visible = !this.card1Visible;
        break;
      case 2:
        this.card2Visible = !this.card2Visible;
        break;
      case 3:
        this.card3Visible = !this.card3Visible;
        break;
      case 4:
        this.card4Visible = !this.card4Visible;
        break;
      case 5:
        this.card5Visible = !this.card5Visible;
        break;
      case 6:
        this.card6Visible = !this.card6Visible;
        break;
    }
  }

  onSubmit2() {

    this.submitted2 = true;
    if (this.registerForm2.invalid) {
      return;
    }
    this.registerForm2.value.telephone = this.registerForm2.value.telephone.internationalNumber
    
    this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
      if (token) {
    this.tokenGenetate = token
    this.registerForm2.value.token = this.tokenGenetate

    this.contactService.createContact(this.registerForm2.value).subscribe(
      (data) => {
        // if (this.modal) {
        //   this.modal.nativeElement.querySelector('.close').click();
        // }
        this.verifyRecaptcha()
        this.onReset2()
        this.iziToast.show({
          message: 'Votre message a été bien envoyé!',
          messageColor: '#21726B',
          progressBarColor: '#21726B',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#e7f5f5',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
        })


      },
      (error) => {
        this.verifyRecaptcha()
        this.onReset2()
        this.iziToast.show({
          message: 'Une erreur est survenue. Merci de saisir vos données!',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          icon: 'bi-exclamation-diamond',
          imageWidth: 45,
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          // overlay: true,
          // overlayClose: true,
        });
        return;

      }

    ); 
      }else{
        this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
        },
        5000);
      return false; 
    }
    }, error => {
      this.iziToast.show({
        message: "Erreur de recaptcha",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        icon: 'bi-exclamation-diamond',
        imageWidth: 45,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        // overlay: true,
        // overlayClose: true,
      });
      setTimeout(() => {
        },
        5000);
      return false;
    });

  }
  onReset2() {
    this.submitted2 = false;
    this.registerForm2.reset();
  }



  initRegisterForm() {
    this.onReset()
  }

  showingDescription: boolean[] = [false, false, false, false, false];

  showDescription(index: number) {
    this.showingDescription[index] = true;
  }

  hideDescription(index: number) {
    this.showingDescription[index] = false;
  }
  redirectToInscriptionMedecin() {
    this.router.navigate(["inscription/medecin"]);
  }
  redirectToInscriptionPatient() {
    this.router.navigate(["inscription/patient"]);
  }
  showMedecin() {
    this.selectedOption = 'medecin';
  }

  showPharmacie() {
    this.selectedOption = 'pharmacie';
  }


  //
  selectSpecilaite(value) {
    if (!value) {
      this.selectedSpecialite = this.specialiites[27];
      this.registerForm.value.specialite = this.specialiites[27]
    }
  }
  ngAfterViewInit(): void {
    // Faire défiler la fenêtre vers le haut au chargement du composant
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
      }
  }

  initFirstForm() {
    this.registerForm = this.formBuilder.group(
      {
        nom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        prenom: [
          '',
          [
            Validators.required,
            Validators.maxLength(25),
            Validators.minLength(3),
          ],
        ],
        email: ['', [Validators.required, Validators.email]],
        typeUser: [false],
        specialite: [],
        telephone: ['', Validators.required],
        message: ['', [Validators.required, Validators.maxLength(200)]],
      },
      {}
    );
  }

  rechercherMedecins(): void {
    let queryParams = {};
    if (this.nomMedecin) {
      queryParams['prenom_nom'] = this.nomMedecin;
    }
    if (this.specialiteMedecin) {
      queryParams['specialite'] = this.specialiteMedecin;
    }
    if (this.villeMedecin) {
      queryParams['localisation'] = this.villeMedecin;
    }
    // Vérifier si l'objet queryParams est vide
    if (Object.keys(queryParams).length === 0) {
      return;
    }
    // Naviguer vers le composant de résultat avec les paramètres dans l'URL
    const url = '/medecin?' + new URLSearchParams(queryParams).toString();
    window.open(url, '_blank');
    //this.router.navigate(['/medecin'], { queryParams: queryParams });
  }


  rechercherPharmacies(): void {
    let queryParams = {};
    if (this.nomPharmacie) {
      queryParams['nom'] = this.nomPharmacie;
    }
    if (this.typePharmacie) {
      queryParams['specialite'] = this.typePharmacie;
    }
    if (this.villePharmacie) {
      queryParams['localisation'] = this.villePharmacie;
    }
    // Vérifier si l'objet queryParams est vide
    if (Object.keys(queryParams).length === 0) {
      return;
    }
    const url = '/pharmacie?' + new URLSearchParams(queryParams).toString();
    window.open(url, '_blank');
  }

  verifyRecaptcha() {
    this.recaptchaV3Service.execute(environment.recaptcha.siteKey)
      .subscribe((token: string) => {
        localStorage.setItem('reCAPTCHAToken', token)
        //this.sendTokenToBackend(token);

        // Envoyer le token au backend pour la vérification
      }, (error: any) => {
        console.error('reCAPTCHA error:', error);
      });
  }
  moreDetails(id: string) {
    this.router.navigate(['/details-article', id]);
  }
  getRandomObjectsFromArray<T>(array: T[], count: number): T[] {
  const shuffledArray = array.slice().sort(() => Math.random() - 0.5);
  return shuffledArray.slice(0, count);
}



openSelect() {
  this.selectMedecin.nativeElement.style.display = 'block';
}

closeSelect() {
  this.selectMedecin.nativeElement.style.display = 'none';
}
onCardClick(eventTitle: string): void {
  // Create a URL-friendly title (slug)
  const slug = this.slugify(eventTitle);
  this.router.navigate(['/event', slug]);
}

slugify(title: string): string {
  return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}

}
