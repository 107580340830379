import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG,_getURL } from 'config/API_CONFIG';
@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private patientUrl = _getURL(API_CONFIG.patient);
  constructor(private http: HttpClient) {}

  AddPatient(patient: any): Observable<Object> {
    return this.http.post(`${this.patientUrl}/complete-profile-data`, patient);
  }

}


